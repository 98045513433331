import {
	toSafeInteger,
	isObject,
	isEmpty,
	capitalize,
	includes,
	toLower,
	identity,
	pickBy,
	cloneDeep,
} from "lodash";
import { SET_ERROR } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import MomentJS from "moment-timezone";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

const validateEmail = (email) => {
	//eslint-disable-next-line
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

class MainSetting {
	install(Vue) {
		Vue.mixin({
			data() {
				return {
					today: null,
					uniqFieldId: null,
					listTitle: [
						{
							value: "mr",
							text: "Mr.",
						},
						{
							value: "ms",
							text: "Ms.",
						},
						{
							value: "mrs",
							text: "Mrs.",
						},
						{
							value: "miss",
							text: "Miss.",
						},
						{
							value: "dr",
							text: "Dr.",
						},
					],
					listAccounting: [
						{
							value: "none",
							text: "NONE",
						},
						{
							value: "fifo",
							text: "FIFO (First In First Out)",
						},
						{
							value: "fefo",
							text: "FEFO (First Expire First Out)",
						},
						{
							value: "lifo",
							text: "LIFO (Last In First Out)",
						},
					],
					listRadioCondition: [
						{
							label: "Yes",
							value: true,
						},
						{
							label: "No",
							value: false,
						},
					],
					durationList: [
						{
							text: "Day",
							value: "day",
						},
						{
							text: "Week",
							value: "week",
						},
						{
							text: "Month",
							value: "month",
						},
					],
					warrantyDurationList: [
						{
							text: "Day",
							value: "day",
						},
						{
							text: "Month",
							value: "month",
						},
						{
							text: "Year",
							value: "year",
						},
					],
					vrules: {
						required(val, field, isInteger) {
							if (isObject(val) && isEmpty(val)) {
								return `${field} is required`;
							}
							if (isInteger && parseInt(val) <= 0) {
								return `${field} is required.`;
							}
							return !!val || `${field} is required`;
						},
						requiredFile(file, field) {
							if (!file) {
								return `${field} is required`;
							}
							if (file && file.size > 0) {
								return true;
							}
						},
						validateQty(val, maxQty, field) {
							let newQty = toSafeInteger(val);
							let newAvailable = toSafeInteger(maxQty);
							if (newQty > newAvailable) {
								return `${field} is must be less than or equal to ${newAvailable}.`;
							}
							return true;
						},
						validEmail(val, field) {
							if (val) {
								if (validateEmail(val)) {
									return true;
								}
								return `${field} must be valid`;
							}
							return true;
						},
						validFloat(val, field) {
							if (val) {
								if (/^\d*\.?\d*$/.test(val)) {
									return true;
								}
								return `${field} must be valid`;
							}
							return true;
						},
						validNumeric(val, field) {
							if (val) {
								if (/^\d+$/.test(val)) {
									return true;
								}
								return `${field} must be valid (only digits) & greater than 0`;
							}
							return true;
						},
						maxLength(val, field, maxLen) {
							if (val) {
								val = val.toString();
								if (val.length <= maxLen) {
									return true;
								}
								return `${field} length must be less than or equal to ${maxLen}`;
							}
							return true;
						},
						minLength(val, field, minLen) {
							if (val) {
								val = val.toString();
								if (val.length >= minLen) {
									return true;
								}
								return `${field} length must be greater than or equal to ${minLen}`;
							}
							return true;
						},
						lessThan(val, field, len) {
							if (val) {
								val = parseInt(val);
								if (val <= len) {
									return true;
								}
								return `${field} must be less than or equal to ${len}`;
							}
							return true;
						},
						greaterThan(val, field, minLen) {
							if (val) {
								val = val.toString();
								if (val.length >= minLen) {
									return true;
								}
								return `${field} length must be greater than or equal to ${minLen}`;
							}
							return true;
						},
						deleteValidation(val, field, barcode) {
							if (val) {
								val = val.toString();
								barcode = barcode.toString();
								if (val === barcode) {
									return true;
								}
								return `${field} must be equal to ${barcode}`;
							}
							return true;
						},
						confirmPassword(val, field, old) {
							if (val) {
								val = val.toString();
								old = old.toString();
								if (val === old) {
									return true;
								}
								return `${field} you entered don't match to new password`;
							}
							return true;
						},
					},
				};
			},
			methods: {
				goBackStepper() {
					this.stepper--;
				},
				deepEmpty(param) {
					return cloneDeep(pickBy(param, identity));
				},
				formatDateTime(dateTime) {
					return MomentJS(dateTime).format("DD/MM/YYYY hh:mm a");
				},
				formatDateTimeRaw(dateTime) {
					return MomentJS(dateTime).format("YYYY-MM-DD HH:mm");
				},
				formatDate(date) {
					return MomentJS(date).format("DD/MM/YYYY");
				},
				formatDateRaw(date) {
					return MomentJS(date).format("YYYY-MM-DD");
				},
				validateForm(formRef) {
					this.$store.commit(SET_ERROR, []);
					const formErrors = [];
					const { inputs } = formRef;
					for (let i = 0; i < inputs.length; i++) {
						const { errorBucket } = inputs[i];
						for (let z = 0; z < errorBucket.length; z++) {
							const errorMessage = capitalize(toLower(errorBucket[z]));
							if (errorMessage) {
								formErrors.push({
									model: true,
									message: errorMessage,
								});
							}
						}
					}
					return formErrors;
				},
				goBack() {
					this.$router.go(-1);
				},
				async logError(error) {
					try {
						console.warn(error);
					} catch (error) {
						console.warn(error);
					}
				},
				stringUnique() {
					let result = "";
					let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
					let charactersLength = characters.length;
					for (let i = 0; i < 10; i++) {
						result += characters.charAt(Math.floor(Math.random() * charactersLength));
					}
					return result;
				},
				eachDayOfInterval({ start, end }) {
					let pre_start = new Date(start);
					let pre_end = new Date(end);

					const output = [];

					while (pre_start < pre_end) {
						output.push(MomentJS(pre_start).format("YYYY-MM-DD"));
						let new_date = pre_start.setDate(pre_start.getDate() + 1);
						pre_start = new Date(new_date);
					}

					return output;
				},
				getPermission(key) {
					try {
						if (this.currentUser && this.currentUser.is_owner == 1) {
							return true;
						}
						let permissions = this.currentUserPermissions;
						if (isEmpty(permissions) === false) {
							let keyArray = key.split(":");
							keyArray[1] = "full-access";
							let fullAccessKey = keyArray.join(":");
							if (includes(permissions, fullAccessKey)) {
								return true;
							}
							return includes(permissions, key);
						} else {
							console.log("destroy");
						}
					} catch (error) {
						this.logError({ error });
						//destroyToken();
						//this.goBack();
					}
				},
				limitDecimal($event, value) {
					if (value) {
						let string = value.toString();
						if (string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) {
							$event.preventDefault();
						}
					}
				},
			},
			mounted() {
				this.today = MomentJS().format("YYYY-MM-DD");
				this.uniqFieldId = this.stringUnique();
			},
			errorCaptured(err, vm, info) {
				this.logError({ err, vm, info });
			},
			computed: {
				...mapGetters(["currentUserPermissions", "currentUser"]),
			},
		});
	}
}

export default new MainSetting();
