import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import listing from "./listing.module";
import contact from "./contact.module";
import common from "./common.module";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		htmlClass,
		config,
		breadcrumbs,
		profile,
		common,
		listing,
		contact,
	},
});
